import Table from './Table';
import Head from './Table.Head';
import Foot from './Table.Foot';
import FootCell from './Table.Foot.Cell';
import Body from './Table.Body';
import HeadCell from './Table.Head.Cell';
import Cell from './Table.Cell';

export default {
  Table,
  Head,
  HeadCell,
  Foot,
  FootCell,
  Body,
  Cell
};
