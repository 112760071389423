import React from 'react';
import styled from '@emotion/styled';
import mediaqueries from '../../styles/media';

// const StyledTable = styled.table`
//   position: relative;
//   line-height: 1.65;
//   color: ${p => p.theme.colors.grey};
//   font-family: ${p => p.theme.fonts.sansSerif};
//   transition: ${p => p.theme.colorModeTransition};
//   background: ${p => p.theme.colors.card};
//   margin: 45px auto 85px;
//   width: 100%;
//   max-width: 1004px;
//   border: 1px solid ${p => p.theme.colors.horizontalRule};
//   border-radius: 5px;
//   overflow: hidden;
//   border-collapse: separate;
//   ${mediaqueries.desktop`
//     margin: 25px auto 65px;
//   `}
//   ${mediaqueries.tablet`
//     max-width: 486px;
//   `};
//   ${mediaqueries.phablet`
//     margin: 15px auto 55px;
//   `};
// `;

const StyledTable = styled.table`
  font-family: ${p => p.theme.fonts.sansSerif};

  position: relative;
  line-height: 1.65;
  color: ${p => p.theme.colors.grey};
  transition: ${p => p.theme.colorModeTransition};
  background: ${p => p.theme.colors.card};

  font-size: 0.95em;

  width: 100%;
  max-width: 1004px;
  overflow: hidden;

  border-collapse: separate;
  border-spacing: 0;
  border: none;

  border-radius: 4px;
  margin: 0.5px auto;
`;

const StyledTableContainer = styled.div`
  max-width: 100%;
  overflow: auto;

  margin: 30px auto 30px;
  border: 1.5px solid ${p => p.theme.colors.horizontalRule};
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 4px 20px;

  background: ${p => p.theme.colors.card};
`;

const Table: React.FC<{}> = ({ children }) => {
  return (
    <StyledTableContainer>
      <StyledTable>{children}</StyledTable>
    </StyledTableContainer>
  );
};

export default Table;
