import styled from '@emotion/styled';

const Cell = styled.th`
  white-space: nowrap;
  padding: 0.15rem 0.5rem;
  font-size: 15px;
  background: ${p => p.theme.colors.tableHeading};
  border-collapse: collapse;
  color: ${p => p.theme.colors.primary};

  border-bottom: 1px solid ${p => p.theme.colors.horizontalRule};
`;

export default Cell;
