import styled from '@emotion/styled';

const Cell = styled.td`
  border-top: 1px solid ${p => p.theme.colors.horizontalRule};
  background: ${p => p.theme.colors.card};

  white-space: nowrap;
  padding: 0.17rem 0.5rem;
  font-size: 14.667px;
  border-collapse: collapse;
  color: ${p => p.theme.colors.articleTextLight};
`;

export default Cell;
