import * as React from 'react';

import styled from '@emotion/styled';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import Container from '../components/Container';
import Page from '../components/Page';
import IndexLayout from '../layouts';
import { TrendsTable } from '../components/TrendsTable';
import { abbreviateNumber } from '../utils/numbers';
import { PageHeader } from '../components/PageHeader';

interface Props {
  pageContext: any;
}

const StyledParagraphBetweenTables = styled.p`
  margin-top: -20px;
`;

export default function CanadaTemplate({
  pageContext: { bsb, canadaPennies, canadaInvestor, wsPennies, dailyBsb, dailyInvestor, renderTime }
}: Props) {
  return (
    <IndexLayout>
      <Page>
        <Container>
          <Helmet title="wsbdaily Canada 🍁" />
          <PageHeader time={renderTime} />
          <h1>Reddit Canadian Investment Trends</h1>
          <p>
            These are the trends on the Canadian investment communities of Reddit, find US and global market trends on our{' '}
            <Link to="/">homepage</Link>.
          </p>
          <p>
            Top stock tickers popular on the Reddit <a href="https://www.reddit.com/r/CanadianInvestor">CanadianInvestor</a> community, of{' '}
            {abbreviateNumber(canadaInvestor.totalSubscribers)} members, over the last {(canadaInvestor.days || 2) * 24}-hours.
          </p>
          <TrendsTable data={canadaInvestor} postsInsteadOfComments />
          <StyledParagraphBetweenTables>
            Top stock tickers popular on the Reddit <a href="https://www.reddit.com/r/baystreetbets">Baystreetbets</a> (Canadian WSB)
            community, of {abbreviateNumber(bsb.totalSubscribers)} members, over the last {(bsb.days || 2) * 24}-hours.
          </StyledParagraphBetweenTables>
          <TrendsTable data={bsb} postsInsteadOfComments />
          <StyledParagraphBetweenTables>
            Top stock tickers popular on the Reddit <a href="https://www.reddit.com/r/Canadapennystocks">CanadaPennystocks</a> community, of{' '}
            {abbreviateNumber(canadaPennies.totalSubscribers)} members, over the last {(canadaPennies.days || 2) * 24}-hours. See our{' '}
            <Link to="/penny">Pennystock</Link> homepage for all penny stock market trends including a scanner.
          </StyledParagraphBetweenTables>
          <TrendsTable data={canadaPennies} postsInsteadOfComments />
          <StyledParagraphBetweenTables>
            Top stock tickers popular on the Reddit <a href="https://www.reddit.com/r/Wealthsimple_Penny">Wealthsimple_Penny</a> community,
            of {abbreviateNumber(wsPennies.totalSubscribers)} members, over the last 3-days. See our <Link to="/penny">Pennystock</Link>{' '}
            homepage for all penny stock market trends including a scanner.
          </StyledParagraphBetweenTables>
          <TrendsTable data={wsPennies} postsInsteadOfComments />
          <TrendsTable
            heading={`${dailyInvestor.post?.title.startsWith('Weekend') ? 'Weekend' : 'Daily'} Discussion Trends`}
            data={dailyInvestor}
          />
          <span style={{ display: 'block', marginTop: '-20px' }}> </span>
          <TrendsTable data={dailyBsb} />
        </Container>
      </Page>
    </IndexLayout>
  );
}
