export function abbreviateNumber(value: number): string {
  let newValue = value;
  if (value >= 100000 && value < 500000) {
    return `${(value / 1000).toFixed(0)}K`;
  }
  if (value >= 100000000 && value < 500000000) {
    return `${(value / 1000000).toFixed(0)}M`;
  }
  if (value >= 1000) {
    const suffixes = ['', 'K', 'M', 'B', 'T'];
    const suffixNum = Math.floor(('' + value).length / 3);
    let shortValue = '';
    for (let precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat((suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision));
      const dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }
    if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue;
}

export function abbreviateVolume(value: number): string {
  if (value < 1000) {
    return value.toString();
  }
  if (value < 1000000) {
    return `${(value / 1000).toFixed(2)}K`;
  }

  return `${(value / 1000000).toFixed(2)}M`;
}

export function addCommas(str: string | number): string {
  const parts = str.toString().split('.');
  let main = parts[0];
  let len = main.length;
  let output = '';
  let first = main.charAt(0);

  if (first === '-') {
    main = main.slice(1);
    len = main.length;
  } else {
    first = '';
  }

  let i = len - 1;
  while (i >= 0) {
    output = main.charAt(i) + output;
    if ((len - i) % 3 === 0 && i > 0) {
      output = `,${output}`;
    }
    --i;
  }
  // put sign back
  output = first + output;
  // put decimal part back
  if (parts.length > 1) {
    output += `.${parts[1]}`;
  }
  return output;
}
